<template lang="pug">
.error
  .top
    img(alt="Delooye logo", src="@/assets/logo_small.png")
    h1 Delooye Payment
  .bottom
    h1 {{ error }}
</template>

<script>
export default {
  props: {
    error: String,
  },
};
</script>
<style lang="scss" scoped>
.error {
  height: 100%;
  width: 100%;
}
.bottom {
  background-color: #ee7623;
}
</style>>