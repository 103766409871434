<template lang="pug">
.loader
  .top
    img(alt="Delooye logo", src="@/assets/logo_small.png")
    h1 Delooye Payment
  .bottom
    .loading
      .spinner
    h2 {{ info }}
</template>

<script>
export default {
  props: {
    info: String,
  },
};
</script>

<style lang="scss" scoped>
.loader {
  height: 100%;
  width: 100%;
}
.loading {
  margin: 3vh;

  .spinner {
    border: 5px solid #f6f6f6;
    border-top: 5px solid #ee7623;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1200px) {
  .loading {
    margin: 3vh;

    .spinner {
      border: 2.5px solid #f6f6f6;
      border-top: 2.5px solid #ee7623;
      width: 50px;
      height: 50px;
    }
  }
}
</style>
